<script>
import Vue from 'vue';
import AlertIntervalEnum from '~/enums/AlertIntervalEnum';
import EnumSelect from '~/components/EnumSelect/EnumSelect';

export default Vue.extend({
  extends: EnumSelect,

  props: {
    getOptionLabel: {
      type: Function,
      required: false,
      default: resource => resource.labelKey,
    },
  },

  data: () => ({
    resources: AlertIntervalEnum.all(),
  }),
});
</script>
