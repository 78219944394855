<template>
  <t-body>
    <t-sidebar>
      <h3>{{ creating ? $t('pages.alert.actions.create') : $t('pages.alert.actions.edit') }}</h3>

      <TLoading v-if="$fetchState.pending" />

      <validation-observer v-else ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
        <t-form @submit.native.prevent="handleSubmit(save)">
          <t-grid compact>
            <t-grid-row>
              <t-grid-cell>
                <t-validation-wrapper
                  vid="label"
                  :name="$t('fields.internal_label')"
                  rules="required|min:3|max:255"
                >
                  <AlertLabelHelp slot="help" />
                  <t-input
                    v-model="form.label"
                    native-type="text"
                    :placeholder="$t('fields.internal_label_placeholder')"
                  />
                </t-validation-wrapper>
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell>
                <t-validation-wrapper
                  vid="interval"
                  :name="$t('fields.interval')"
                  rules="required|string"
                >
                  <alert-interval-select v-model="form.interval" />
                </t-validation-wrapper>
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell>
                <t-validation-wrapper
                  vid="radius"
                  :name="$t('fields.search_radius')"
                  rules="required|integer"
                >
                  <search-radius-select v-model="radius" />
                </t-validation-wrapper>
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell>
                <t-validation-wrapper
                  vid="form.graduation_type_ids"
                  :name="$t('fields.graduation_types')"
                  rules=""
                >
                  <GraduationTypeSelect v-model="form.graduation_type_ids" multiple />
                </t-validation-wrapper>
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell>
                <t-validation-wrapper
                  vid="form.occupation_ids"
                  :name="$t('fields.occupations')"
                  rules=""
                >
                  <OccupationSelect v-model="form.occupation_ids" multiple />
                </t-validation-wrapper>
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell>
                <t-validation-wrapper
                  vid="form.employee_ids"
                  :name="$t('fields.employees_to_notify')"
                  rules="required"
                >
                  <EmployeeSelect
                    v-model="form.employee_ids"
                    multiple
                    :organization-id="organizationId"
                  />
                </t-validation-wrapper>
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <div
                style="
                  flex-direction: row-reverse;
                  display: flex;
                  width: 100%;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <t-button-loading :disabled="invalid" :loading="loading" native-type="submit">
                  {{ $t('global.actions.save') }}
                </t-button-loading>

                <a v-if="!creating" href="." @click.prevent="deleteConfirmationVisible = true">{{
                  $t('global.actions.delete')
                }}</a>
              </div>
            </t-grid-row>
          </t-grid>
        </t-form>
      </validation-observer>
    </t-sidebar>

    <t-page id="map" />

    <portal to="modals">
      <t-confirm
        v-if="deleteConfirmationVisible"
        :button-label="$t('global.actions.confirm_deletion')"
        @confirmed="destroy"
        @cancelled="deleteConfirmationVisible = false"
      >
        <template #header>
          {{ $t('global.confirmation.delete') }}
        </template>
        <p>{{ $t('global.confirmation.final_action') }}</p>
      </t-confirm>
    </portal>
  </t-body>
</template>

<script>
import Vue from 'vue';
import Alert from '~/models/Alert';
import TLoading from '~/components/TLoading';
import TValidationWrapper from '~/components/TValidationWrapper';
import AlertIntervalSelect from '~/components/EnumSelect/AlertIntervalSelect';
import SearchRadiusSelect from '~/components/EnumSelect/SearchRadiusSelect';
import GraduationTypeSelect from '~/components/ResourceSelect/GraduationTypeSelect';
import OccupationSelect from '~/components/ResourceSelect/OccupationSelect';
import EmployeeSelect from '~/components/ResourceSelect/EmployeeSelect';
import SearchRadiusEnum from '~/enums/SearchRadiusEnum';
import useSearchMap from '~/composables/useSearchMap';
import AlertLabelHelp from '~/components/Help/AlertLabelHelp';
import UserTypeEnum from '~/enums/UserTypeEnum';

export default Vue.extend({

  components: {
    TLoading,
    TValidationWrapper,
    AlertIntervalSelect,
    SearchRadiusSelect,
    GraduationTypeSelect,
    OccupationSelect,
    EmployeeSelect,
    AlertLabelHelp,
  },

  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },

  setup() {
    const { radius, position, buildMap, makeUi } = useSearchMap();

    radius.value = SearchRadiusEnum.TWENTYFIVE;

    return { radius, position, buildMap, makeUi };
  },

  data() {
    return {
      alert: null,
      form: null,
      loading: false,

      deleteConfirmationVisible: false,
    };
  },

  async fetch() {
    if (this.$route.params?.alert_id) {
      this.alert = await Alert.$find(this.$route.params.alert_id);
    }

    this.form = this.getForm(this.alert);
    this.setupMap(this.alert);
  },

  computed: {
    creating() {
      return !(this.alert && this.alert.id);
    },
  },

  async mounted() {
    await this.buildMap(document.getElementById('map'), {
      zoom: 8,
    });
    this.makeUi();

    if (!this.$auth.isEmployee) {
      return;
    }

    const { latitude, longitude } = this.$auth.me.organization.address.position;

    this.position = {
      lat: latitude,
      lng: longitude,
    };
  },

  methods: {
    getForm(alert = null) {
      return {
        label: alert?.label,
        interval: alert?.interval,
        active: alert?.active,
        graduation_type_ids: alert?.graduation_type_ids,
        occupation_ids: alert?.occupation_ids,
        employee_ids: alert?.employee_ids,
        organization_id: this.organizationId,
      };
    },

    setupMap(alert = null) {
      if (alert?.parameters?.radius) {
        this.radius = alert.parameters.radius;
      }
      if (alert?.parameters?.position) {
        this.position = {
          lat: alert.parameters.position.latitude,
          lng: alert.parameters.position.longitude,
        };
      }
    },

    getPayload() {
      return {
        ...this.form,
        parameters: {
          ...this.form.parameters,
          radius: this.radius,
          position: {
            latitude: this.position.lat,
            longitude: this.position.lng,
          },
        },
      };
    },

    rerouteToIndex() {
      const destinationRoute = [UserTypeEnum.ADMIN, UserTypeEnum.TENANT_MEMBER].includes(
        this.$auth.me.user.type,
      )
        ? { name: this.$RouteEnum.ORGANIZATION.ALERTS, params: { id: this.organizationId } }
        : { name: this.$RouteEnum.ALERTS.INDEX };
      return this.$router.push(destinationRoute);
    },

    async save() {
      if (this.creating) {
        return await this.create(this.getPayload());
      }

      return await this.update(this.getPayload());
    },

    async create(payload) {
      this.loading = true;
      try {
        await new Alert({
          ...payload,
        }).save();

        await this.$notify.success(this.$t('notifications.alert.created'));
        await this.rerouteToIndex();
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },

    async update(payload) {
      this.loading = true;
      try {
        await new Alert({
          id: this.alert.id,
          ...payload,
        }).patch();

        await this.$notify.success(this.$t('notifications.alert.updated'));
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },

    async destroy() {
      try {
        await new Alert({
          id: this.alert.id,
        }).delete();

        await this.$notify.success(this.$t('notifications.alert.deleted'));
        await this.rerouteToIndex();

        this.deleteConfirmationVisible = false;
      } catch (e) {
        this.$axios.handleError(e);
      }
    },
  },
});
</script>
