<script>
import Vue from 'vue';
import Employee from '~/models/Employee';
import ResourceSelect from '~/components/ResourceSelect/ResourceSelect.vue';

export default Vue.extend({
  extends: ResourceSelect,

  props: {
    getOptionLabel: {
      type: Function,
      required: false,
      default: resource => resource?.user?.fullname,
    },

    organizationId: {
      type: String,
      required: true,
    },

    query: {
      type: Object,
      required: false,
      default: () => new Employee().include('user'),
    },
  },

  methods: {
    fetch() {
      return this.query.where('organization_id', this.organizationId).$all();
    },
  },
});
</script>
