<script>
import Vue from 'vue';
import SearchRadiusEnum from '~/enums/SearchRadiusEnum';
import EnumSelect from '~/components/EnumSelect/EnumSelect';

export default Vue.extend({
  extends: EnumSelect,

  data: () => ({
    resources: SearchRadiusEnum.all(),
  }),
});
</script>
