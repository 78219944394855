<script>
import THelp from '~/components/THelp';

export default {
  extends: THelp,

  props: {
    label: {
      type: String,
      required: false,
      default:
        'Geben Sie der Hintergrundsuche eine zusammenfassende Bezeichnung, damit Sie einen guten Überblick behalten. (z.B. Chemikant:innen, Pharmakant:innen)',
    },

    position: {
      type: String,
      required: false,
      default: 'bottom',
    },
  },
};
</script>
