class SearchRadiusEnum {
  static FIVE = 5000;
  static TEN = 10000;
  static TWENTYFIVE = 25000;
  static FIFTY = 50000;
  static HUNDRED = 100000;

  static labelKey(item: number) {
    return SearchRadiusEnum.meta(item)?.labelKey;
  }

  static meta(item: number) {
    switch (item) {
      case SearchRadiusEnum.FIVE:
        return {
          labelKey: '5km',
        };
      case SearchRadiusEnum.TEN:
        return {
          labelKey: '10km',
        };
      case SearchRadiusEnum.TWENTYFIVE:
        return {
          labelKey: '25km',
        };
      case SearchRadiusEnum.FIFTY:
        return {
          labelKey: '50km',
        };
      case SearchRadiusEnum.HUNDRED:
        return {
          labelKey: '100km',
        };
    }
  }

  static all() {
    return [
      {
        key: SearchRadiusEnum.FIVE,
        labelKey: SearchRadiusEnum.labelKey(SearchRadiusEnum.FIVE),
      },
      {
        key: SearchRadiusEnum.TEN,
        labelKey: SearchRadiusEnum.labelKey(SearchRadiusEnum.TEN),
      },
      {
        key: SearchRadiusEnum.TWENTYFIVE,
        labelKey: SearchRadiusEnum.labelKey(SearchRadiusEnum.TWENTYFIVE),
      },
      {
        key: SearchRadiusEnum.FIFTY,
        labelKey: SearchRadiusEnum.labelKey(SearchRadiusEnum.FIFTY),
      },
      {
        key: SearchRadiusEnum.HUNDRED,
        labelKey: SearchRadiusEnum.labelKey(SearchRadiusEnum.HUNDRED),
      },
    ];
  }
}

export default SearchRadiusEnum;
